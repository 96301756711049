import { defineStore } from 'pinia';
import type { AgencyBudgetLabel } from '@prisma/client';
import type { CreativeExtended } from '~/types/creatives';
import type { ProjectExtended } from '~/types/projects';

export const useModuleStore = defineStore('ModuleStore', {
  state: () => {
    return {
      currentCreative: null as CreativeExtended | null,
      currentProject: null as ProjectExtended | null,
      currentCommentCtxOrder: null as number | null,
      budgetLabels: null as AgencyBudgetLabel[] | null,
      budgetLabelsExpire: new Date(Date.now() - 1000 * 10) as Date,
    };
  },
  actions: {
    setCurrentCreative(creative: CreativeExtended | null) {
      this.currentCreative = creative;
    },
    setCurrentProject(project: ProjectExtended | null) {
      this.currentProject = project;
    },
    reset() {
      this.currentCreative = null;
      this.currentProject = null;
      this.currentCommentCtxOrder = null;
      this.budgetLabels = null;
      this.budgetLabelsExpire = new Date(Date.now() - 1000 * 10);
    },
  },
});
